import { darken, lighten, toHex } from "color2k";

import { WebsiteConfigurationDto } from "./configuration-dtos";

/* don't delete these classes

   bg-theme-primary bg-theme-primary-100 bg-theme-primary-200 bg-theme-primary-300 bg-theme-primary-400 bg-theme-primary-500 bg-theme-primary-600 bg-theme-primary-700 bg-theme-primary-800 bg-theme-primary-900

   bg-theme-text bg-theme-text-100 bg-theme-text-200 bg-theme-text-300 bg-theme-text-400 bg-theme-text-500 bg-theme-text-600 bg-theme-text-700 bg-theme-text-800 bg-theme-text-900

   bg-theme-background bg-theme-background-100 bg-theme-background-200 bg-theme-background-300 bg-theme-background-400 bg-theme-background-500 bg-theme-background-600 bg-theme-background-700 bg-theme-background-800 bg-theme-background-900

   bg-theme-secondary bg-theme-secondary-100 bg-theme-secondary-200 bg-theme-secondary-300 bg-theme-secondary-400 bg-theme-secondary-500 bg-theme-secondary-600 bg-theme-secondary-700 bg-theme-secondary-800 bg-theme-secondary-900

   bg-theme-accent bg-theme-accent-100 bg-theme-accent-200 bg-theme-accent-300 bg-theme-accent-400 bg-theme-accent-500 bg-theme-accent-600 bg-theme-accent-700 bg-theme-accent-800 bg-theme-accent-900

  */

const myNiceHexRegex = /^#([A-Fa-f0-9]{6}|[A-Fa-f0-9]{3})$/;

export function generateColorVariables(
  config: WebsiteConfigurationDto | undefined,
) {
  const vars: Record<string, string> = {};

  const globalLightScale = {
    100: 0.2,
    200: 0.15,
    300: 0.1,
    400: 0.05,
  };

  const textLightScale = {
    100: 0.4,
    200: 0.35,
    300: 0.3,
    400: 0.25,
  };

  const darkScale = {
    600: 0.05,
    700: 0.1,
    800: 0.15,
    900: 0.2,
  };

  const setVariableColor = (name: string, color: string) => {
    vars[name] = color;

    const lightScale =
      name === "--theme-text-color" ? textLightScale : globalLightScale;

    Object.entries(lightScale).forEach(([level, amount]) => {
      vars[`${name}-${level}`] = toHex(lighten(color, amount));
    });

    vars[`${name}-500`] = color;

    Object.entries(darkScale).forEach(([level, amount]) => {
      vars[`${name}-${level}`] = toHex(darken(color, amount));
    });
  };

  if (
    config?.globalConfiguration?.theme?.primaryColor &&
    myNiceHexRegex.test(config.globalConfiguration.theme.primaryColor)
  ) {
    setVariableColor(
      "--theme-primary-color",
      config?.globalConfiguration?.theme?.primaryColor,
    );
  } else {
    setVariableColor("--theme-primary-color", "#000000");
  }

  if (
    config?.globalConfiguration?.theme?.textColor &&
    myNiceHexRegex.test(config.globalConfiguration.theme.textColor)
  ) {
    setVariableColor(
      "--theme-text-color",
      config?.globalConfiguration?.theme?.textColor,
    );
  } else {
    setVariableColor("--theme-text-color", "#000000");
  }

  if (
    config?.globalConfiguration?.theme?.backgroundColor &&
    myNiceHexRegex.test(config.globalConfiguration.theme.backgroundColor)
  ) {
    setVariableColor(
      "--theme-background-color",
      config?.globalConfiguration?.theme?.backgroundColor,
    );
  } else {
    setVariableColor("--theme-background-color", "#ffffff");
  }

  if (
    config?.globalConfiguration?.theme?.secondaryColor &&
    myNiceHexRegex.test(config.globalConfiguration.theme.secondaryColor)
  ) {
    setVariableColor(
      "--theme-secondary-color",
      config.globalConfiguration.theme.secondaryColor,
    );
  }

  if (
    config?.globalConfiguration?.theme?.accentColor &&
    myNiceHexRegex.test(config.globalConfiguration.theme.accentColor)
  ) {
    setVariableColor(
      "--theme-accent-color",
      config.globalConfiguration.theme.accentColor,
    );
  }

  return vars;
}

export function GetCssVars(config: WebsiteConfigurationDto | undefined) {
  const vars = generateColorVariables(config);
  return vars;
}
