"use client";

import { ibmPlex } from "@website-toolkit/lib/font-utils";

import { LoadingProvider } from "./_providers/loading-provider";
import { Providers } from "./_providers/providers";

import "../globals.css";

import { GetCssVars } from "@website-toolkit/lib/colors";

export default function RootLayout({
  children,
}: Readonly<{
  children: React.ReactNode;
}>) {
  return (
    <html lang="ar" className="" dir="rtl">
      <body className={ibmPlex.className} style={GetCssVars(undefined)}>
        <LoadingProvider>
          <Providers>{children}</Providers>
        </LoadingProvider>
      </body>
    </html>
  );
}
