"use client";

import { useCallback, useEffect, useState } from "react";

import { QueryClient, QueryClientProvider } from "@tanstack/react-query";
import { ReactQueryDevtools } from "@tanstack/react-query-devtools";
import deepEqual from "deep-equal";
import { SessionProvider, signIn, signOut, useSession } from "next-auth/react";

import { I18nProviderClient } from "@libs/providers/locales/client";

import RekazLoader from "@repo/ui/components/rekaz-loader";

import { AppStoreProvider } from "@repo/website-toolkit/src/components/Providers/app-store-provider";
import { CartProvider } from "@website-toolkit/components/Providers/CartProvider";

import { parseJwt, useAuthStore } from "@/stores/useAuthStore";

function makeQueryClient() {
  return new QueryClient({
    defaultOptions: {
      queries: {
        staleTime: 60 * 1000,
      },
    },
  });
}

let browserQueryClient: QueryClient | undefined = undefined;

function getQueryClient() {
  if (typeof window === "undefined") {
    return makeQueryClient();
  } else {
    if (!browserQueryClient) browserQueryClient = makeQueryClient();
    return browserQueryClient;
  }
}
export function Providers({ children }: { children: React.ReactNode }) {
  const queryClient = getQueryClient();
  return (
    <I18nProviderClient locale={"ar"}>
      <AppStoreProvider config={{ settings: {} } as any}>
        <QueryClientProvider client={queryClient}>
          <CartProvider adminMode>{children}</CartProvider>
          <ReactQueryDevtools initialIsOpen={false} />
        </QueryClientProvider>
      </AppStoreProvider>
    </I18nProviderClient>
  );
}
