import { NextFont } from "next/dist/compiled/@next/font";
import { IBM_Plex_Sans_Arabic, Rubik, Cairo, Tajawal, Almarai, Readex_Pro, Lalezar, Mada, Reem_Kufi, Gulzar, Noto_Kufi_Arabic } from "next/font/google"

// ibmPlex, Rubic, Cairo, Tajawal, Almarai, Readex Pro, Lalezar, Mada, Reem Kifi, Gulzar, Noto Kufi Arabic 


export const ibmPlex = IBM_Plex_Sans_Arabic({
    subsets: ["arabic", "latin"],
    weight: ["400", "700"],
});

export const rubik = Rubik({
    subsets: ["arabic", "latin"],
    weight: ["400", "700"],
});

export const cairo = Cairo({
    subsets: ["arabic", "latin"],
    weight: ["400", "700"],
});

export const tajawal = Tajawal({
    subsets: ["arabic", "latin"],
    weight: ["400", "700"],
});

export const almarai = Almarai({
    subsets: ["arabic"],
    weight: ["400", "700"],
});

export const readexPro = Readex_Pro({
    subsets: ["arabic", "latin"],
    weight: ["400", "700"],
});

export const lalezar = Lalezar({
    subsets: ["arabic", "latin"],
    weight: ["400"],
});

export const mada = Mada({
    subsets: ["arabic", "latin"],
    weight: ["400", "700"],
});

export const reemKufi = Reem_Kufi({
    subsets: ["arabic", "latin"],
    weight: ["400", "700"],
});

export const gulzar = Gulzar({
    subsets: ["arabic", "latin"],
    weight: ["400"],
});

export const notoKufiArabic = Noto_Kufi_Arabic({
    subsets: ["arabic"],
    weight: ["400", "700"],
});


export const fonts: Record<string, NextFont> = {
    ibmPlex,
    rubik,
    cairo,
    tajawal,
    almarai,
    readexPro,
    lalezar,
    mada,
    reemKufi,
    gulzar,
    notoKufiArabic
}

